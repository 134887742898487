<template>
  <div class="conduc-index">
    <div class="conduc-content">
      <CustomHome @goStaring="goStaring" v-if="customActiveIndex === 0" />
      <CustomStarting v-if="customActiveIndex === 1" />
    </div>
    <div class="conduc-tarbarBox">
      <div class="conduc-tarbar" @click="changeCustomPage(0)">
        <img
          src="@/assets/images/tarbar1_check.png"
          v-if="customActiveIndex === 0"
          alt=""
        />
        <img src="@/assets/images/tarbar1.png" v-else alt="" />
        <p>Home</p>
      </div>
      <div class="conduc-tarbar2" @click="changeCustomPage(1)">
        <img src="@/assets/images/tarbar2.png" alt="" />
        <p>Starting</p>
      </div>
      <div class="conduc-tarbar" @click="changeCustomPage(2)">
        <img
          src="@/assets/images/tarbar3_check.png"
          v-if="customActiveIndex === 2"
          alt=""
        />
        <img src="@/assets/images/tarbar3.png" v-else alt="" />
        <p>Records</p>
      </div>
    </div>
  </div>
</template>

<script>
import CustomHome from "./components/home.vue";
import CustomStarting from "./components/starting";

export default {
  components: {
    CustomHome,
    CustomStarting,
  },
  data() {
    return {
      customActiveIndex: 0,
    };
  },
  methods: {
    changeCustomPage(index) {
      if (index === 2) {
        this.$router.push("/records/records");
        return false;
      }
      this.customActiveIndex = index;
      sessionStorage.setItem("customHomeIndex", index);
    },
    goStaring() {
      this.changeCustomPage(1);
    },
  },
  created() {
    if (sessionStorage.getItem("customHomeIndex")) {
      this.customActiveIndex = parseInt(sessionStorage.getItem("customHomeIndex"));
    }
  },
};
</script>

<style lang="less">
.conduc-index {
  height: 100%;
  display: flex;
  flex-direction: column;
  .conduc-content {
    flex: 1;
  }
  .conduc-tarbarBox {
    width: 100%;
    height: 84px;
    position: fixed;
    z-index: 100;
    background: url("@/assets/images/tarbar_bg.png") no-repeat;
    background-size: cover;
    bottom: 0;
    left: 0;
    display: flex;
    .conduc-tarbar {
      flex: 1;
      flex-grow: 1;
      img {
        width: 34px;
        display: block;
        margin: 5px auto 0;
      }
      p {
        color: #fff;
        text-align: center;
        font-size: 12px;
      }
    }
    .conduc-tarbar2 {
      width: 76px;
      margin-top: -20px;
      img {
        width: 100%;
      }
      p {
        color: #fff;
        text-align: center;
        font-size: 12px;
        margin-top: -5px;
      }
    }
  }
}
</style>
