<template>
  <div class="conduc-regeist">
    <div class="conduc-head">
      <h1>Register</h1>
      <img src="@/assets/images/logo.png" alt="" class="conduc-logo" />
    </div>
    <div class="conduc-content">
      <h2>Register</h2>
      <div class="conduc-tips">Please register to access more content</div>
      <div class="conduc-textbox">
        <span>Username</span>
        <div class="conduc-inputBox">
          <input
            type="text"
            class="conduc-inputtext"
            v-model="form.username"
            placeholder="phone/username"
          />
        </div>
      </div>
      <div class="conduc-textbox">
        <span>Password</span>
        <div class="conduc-inputBox">
          <input
            type="password"
            v-model="form.password"
            class="conduc-inputtext"
            placeholder="Type Password"
          />
        </div>
      </div>
      <div class="conduc-textbox">
        <span>Phone Number</span>
        <div class="conduc-inputBox">
          <input
            type="text"
            v-model="form.phone"
            class="conduc-inputtext"
            placeholder="Type Phone Number"
            @input="restrictToNumbersAndPlus"
          />
        </div>
      </div>
      <div class="conduc-textbox">
        <span>Invitation Code</span>
        <div class="conduc-inputBox">
          <input
            type="text"
            v-model="form.inviteCode"
            class="conduc-inputtext"
            placeholder="Type Invitation Code"
          />
        </div>
      </div>
      <div class="conduc-agreeBox">
        <div class="conduc-checkBox" @click="isChecked = !isChecked">
          <img src="@/assets/images/check.png" v-if="!isChecked" alt="" />
          <img src="@/assets/images/checked.png" v-else alt="" />
        </div>
        <span>Agree with</span>
        <a href="javascript:;" @click="goAgree()">《User Registration Agreement》</a>
      </div>
      <div class="conduc-df_btn" @click="regeist()">Register Now</div>
      <div class="conduc-regeist_btn" @click="login()">Login Now</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {},
  data() {
    return {
      isChecked: false,
      form: {
        inviteCode: "",
        password: "",
        phone: "",
        username: "",
      },
    };
  },
  methods: {
    login() {
      this.$router.push("/login/login");
    },
    restrictToNumbersAndPlus(event) {
      let inputValue = event.target.value;
      let numericValue = inputValue.replace(/[^0-9+]/g, "");
      this.form.phone = numericValue;
    },
    goAgree(){
      this.$router.push("/agreement/agreement");
    },
    regeist() {
      if (!this.isChecked) {
        this.showInfo('Read the 《User Registration Agreement》 first')
        return;
      }
      if(this.form.username.includes(' ')){
        this.showInfo('Account cannot have spaces');
        return;
      }
      if(this.form.phone.includes(' ')){
        this.showInfo('Phone number cannot have spaces');
        return;
      }
      if(this.form.inviteCode.includes(' ')){
        this.showInfo('Invitation code cannot have spaces');
        return;
      }
      this.getAjax('api/en/iiwnzhuwpamb/xuwyzkawwn/register',{...this.form},'post').then(res=>{
        this.showInfo(res.data,'',()=>{
          this.$router.push("/login/login");
        })
      })
    },
  },
  async created() {},
};
</script>

<style lang="less" scoped>
.conduc-regeist {
  .conduc-head {
    width: 100%;
    height: 40vh;
    background: url("@/assets/images/nav_bg.png") no-repeat;
    background-size: cover;
    .conduc-logo {
      width: 250px;
      display: block;
      margin: 20px auto;
    }
    h1 {
      color: #fff;
      font-size: 18px;
      height: 36px;
      line-height: 34px;
      padding: 0 15px;
    }
    p {
      font-size: 12px;
      color: #97bec3;
      padding: 0 10px;
    }
  }
  .conduc-content {
    padding: 15px;
    h2 {
      font-size: 20px;
      color: #27a3c7;
      padding: 15px 0;
    }
    .conduc-tips {
      font-size: 14px;
      color: #666;
    }
    .conduc-agreeBox {
      display: flex;
      align-items: center;
      margin-top: 10px;
      color: #27a3c7;
      .conduc-checkBox {
        width: 24px;
        height: 24px;
        margin-right: 5px;
        img {
          width: 100%;
        }
      }
      a {
        color: #27a3c7;
        text-decoration: underline;
      }
    }
    .conduc-textbox {
      margin-top: 10px;
      border: 2px solid #c1e7ee;
      border-radius: 5px;
      display: flex;
      align-items: center;
      padding: 15px;
      span {
        margin-right: 15px;
        color: #27a3c7;
        font-size: 13px;
      }
      .conduc-inputBox {
        flex: 1;
        .conduc-inputtext {
          border: 0;
          outline: none;
          text-align: right;
          width: 100%;
        }
      }
    }
    .conduc-forget {
      font-size: 20px;
      margin-top: 30px;
      text-align: center;
      text-decoration: underline;
      font-weight: bold;
      color: #27a3c7;
    }
    .conduc-df_btn {
      margin: 15px auto;
    }
    .conduc-regeist_btn {
      width: 60%;
      height: 32px;
      line-height: 32px;
      text-align: center;
      background-image: linear-gradient(to right, #25abc4, #1acbb5);
      background-size: cover;
      color: #fff;
      margin: 0 auto;
      border-radius: 5px;
      font-size: 14px;
      opacity: 0.4;
    }
  }
}
</style>
