<template>
  <div class="conduc-myInfo">
    <Header title="Personal Info" />
    <div class="content">
      <div class="infoBox">
        <div class="line">
          <span class="conduc-name">Profile Image</span>
          <div class="conduc-value">
            <van-uploader :after-read="updateHeader">
              <template>
                <img
                  :src="userInfo.headImg"
                  style="width: 50px; height: 50px; border-radius: 100px"
                  alt=""
                />
                <img
                  src="@/assets/images/right.png"
                  alt=""
                  style="width: 8px; height: 13px; margin-left: 5px"
                />
              </template>
            </van-uploader>
          </div>
        </div>
        <div class="line">
          <span class="conduc-name">Username</span>
          <div class="conduc-value">{{ userInfo.userName }}</div>
        </div>
        <div class="line" @click="goChangeLogin()">
          <span class="conduc-name">Change login password</span>
          <div class="conduc-value">
            <img
              src="@/assets/images/right.png"
              alt=""
              style="width: 8px; height: 13px; margin-left: 5px"
            />
          </div>
        </div>
        <div class="line" @click="goPayPwd()">
          <span class="conduc-name">Change payment password</span>
          <div class="conduc-value">
            <img
              src="@/assets/images/right.png"
              alt=""
              style="width: 8px; height: 13px; margin-left: 5px"
            />
          </div>
        </div>
        <div class="line" @click="goPage('/changeFullName/changeFullName')">
          <span class="conduc-name">Full name</span>
          <div class="conduc-value">
            {{ userInfo.realName }}
            <img
              src="@/assets/images/right.png"
              alt=""
              style="width: 8px; height: 13px; margin-left: 5px"
            />
          </div>
        </div>
        <div class="line" @click="goPage('/changePhone/changePhone')">
          <span class="conduc-name">Phone</span>
          <div class="conduc-value">
            {{ userInfo.phone }}
            <img
              src="@/assets/images/right.png"
              alt=""
              style="width: 8px; height: 13px; margin-left: 5px"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
export default {
  components: { Header },
  data() {
    return {
      userInfo: {},
    };
  },
  methods: {
    goPage(page){
      this.$router.push(page);
    },
    goChangeLogin(){
      this.$router.push('/changePwd/changePwd');
    },
    goPayPwd(){
      this.$router.push('/changePayPwd/changePayPwd');
    },
    async updateHeader(file) {
      this.showLoading();
      const data = new FormData();
      data.append("file", file.file);
      data.append("width", 100);
      const res = await this.getAjax(
        "api/en/order/public/uploadImg/userHead",
        data,
        "post"
      );
      const downloadUrl = res.data.downloadUrl;
      const res2 = await this.getAjax(
        "api/en/iiwnzhuwpamb/xuwyzkawwn/updateHeadImg",
        {
          imgUrl: downloadUrl,
        },
        "post"
      );
      this.initDate();
    },
    initDate() {
      //获取用户信息
      this.getAjax("api/en/iiwnzhuwpamb/xuwyzkawwn/getBalance", {}, "get").then((res) => {
        this.hideLoading();
        this.userInfo = res.data;
      });
    },
  },
  mounted() {
    this.initDate();
  },
};
</script>

<style lang="less" scoped>
.conduc-myInfo {
  padding-top: 90px;
  .content {
    padding: 0 15px 15px;
    line-height: 1.6;
    .infoBox {
      border: 1px solid #c0e6ee;
      border-radius: 4px;
      padding: 0 10px;
      .line {
        height: 60px;
        border-bottom: 1px solid #c0e6ee;
        display: flex;
        align-items: center;
        .conduc-name {
          font-size: 15px;
          color: #7f8797;
        }
        .conduc-value {
          flex: 1;
          display: flex;
          justify-content: flex-end;
          color: #36b2c8;
          align-items: center;
        }
        &:last-child {
          border-bottom: 0;
        }
      }
    }
  }
}
</style>
