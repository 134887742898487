<template>
	<div class="conduc-starting">
		<div class="conduc-head">
			<img src="@/assets/images/menu.png" alt="" class="conduc-menu" @click="handleClickMenu()" />
			<div class="conduc-title">Starting</div>
		</div>
		<div class="conduc-content">
			<h1>TOP APPS THIS WEEK</h1>
			<div class="conduc-lunboBox">
				<div v-show="showLunbo" style="overflow: hidden; width: 100%; height: 280px; display: flex">
					<vue-seamless-scroll :data="listData" :class-option="defaultOption" style="width: 400px">
						<ul class="conduc-ul-scoll" id="imgBox">
							<li v-for="(item, index) in listData" :key="index">
								<img :src="item.imgUrl" @click="goApp(item)" />
							</li>
						</ul>
					</vue-seamless-scroll>
				</div>
			</div>
			<div class="conduc-appBox">
				<div v-for="item in 15" :key="item" class="conduc-apps">
					<img :src="require('@/assets/images/' + item + '.png')" alt="" class="conduc-app" />
				</div>
			</div>
			<div class="conduc-num">{{ orderInfo.todayCount }}/{{ orderInfo.maxCount }}</div>
			<div class="conduc-level">
				<img v-if="orderInfo.vipLevel === '1'" src="@/assets/images/level1.png" alt="" />
				<img v-if="orderInfo.vipLevel === '2'" src="@/assets/images/level2.png" alt="" />
				<img v-if="orderInfo.vipLevel === '3'" src="@/assets/images/level3.png" alt="" />
				<img v-if="orderInfo.vipLevel === '4'" src="@/assets/images/level4.png" alt="" />
				<span>{{ orderInfo.fanDian }}% profit</span>
			</div>
			<div class="conduc-df_btn" @click="startOrder()">Start</div>
			<div v-if="showGifAnimation" class="conduc-gif-animation-wrapper">
				<img src="@/assets/images/loading.gif" alt="Loading..." />
			</div>
			<div class="conduc-jieshaoBox">
				<div class="conduc-item">
					<img src="@/assets/images/jieshao1.png" alt="" />
					<h3>Today profit</h3>
					<h4>USDT {{ userInfo.todayProfit }}</h4>
					<p>It will be updated daily. Only today's profits are shown here</p>
				</div>
				<div class="conduc-item">
					<img src="@/assets/images/jieshao2.png" alt="" />
					<h3>Every Ratings</h3>
					<h4>USDT {{ userInfo.walletAmount }}</h4>
					<p>Every rating's profit will add to total assets balance</p>
				</div>
			</div>
			<div class="conduc-tips">
				<div class="conduc-title">Important Notice</div>
				<p>Business Hours {{ times.startTime }} - {{ times.endTime }}</p>
				<p>For further assistance, please contact Customer Care</p>
			</div>
		</div>
		<Slide ref="slide" />
		<van-popup v-model="showPop" round position="bottom">
			<div class="conduc-task">
				<h2>
					Rating Submission
					<van-icon name="cross" @click="showPop = false" class="conduc-iconClose" color="#666" />
				</h2>
				<img :src="info.imgUrl" alt="" style="width: 135px" />
				<h3>{{ info.title }}</h3>
				<div class="conduc-money">
					<div class="conduc-item">
						<div class="conduc-name">Total amount</div>
						<div class="conduc-value">USDT {{ info.totalAmount }}</div>
					</div>
					<div class="conduc-item">
						<div class="conduc-name">Profit</div>
						<div class="conduc-value">USDT {{ info.profit }}</div>
					</div>
				</div>
				<div class="conduc-line">
					<span>Creation time</span>
					<em>{{ info.createTime }}</em>
				</div>
				<div class="conduc-line">
					<span>Rating No.</span>
					<em>{{ info.recordId }}</em>
				</div>
				<div class="conduc-df_btn" @click="submitTask()">Submit</div>
			</div>
		</van-popup>
	</div>
</template>

<script>
	import vueSeamlessScroll from "vue-seamless-scroll";
	import Slide from "@/components/slide.vue";
	export default {
		components: {
			vueSeamlessScroll,
			Slide
		},
		data() {
			return {
				listData: [{}, {}],
				showPop: false,
				showLunbo: false,
				info: {},
				orderInfo: {}, //接单信息
				userInfo: {},
				times: {
					startTime: "",
					endTime: "",
				}, //营业时间
				showGifAnimation: false,
			};
		},
		computed: {
			defaultOption() {
				return {
					step: 1, // 数值越大速度滚动越快
					limitMoveNum: 2, // 开始无缝滚动的数据量 this.dataList.length
					hoverStop: false, // 是否开启鼠标悬停stop
					direction: 2, // 0向下 1向上 2向左 3向右
					openWatch: false, // 开启数据实时监控刷新dom
					singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
					singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
					waitTime: 0, // 单步运动停止的时间(默认值1000ms)
				};
			},
		},
		methods: {
			
			async startOrder() {
			  const res = await this.getAjax("api/en/iiwnzhuwpamb/xuwyzkawwn/takeOrder", {}, "get");
			  this.info = res.data;
			  this.initPage();
			  this.showPop = true;
			},
			goApp(item) {
				window.open(item.clickUrl, "_blank");
			},
			handleClickMenu() {
				sessionStorage.setItem("slide", true);
				this.$refs.slide.showSlide = true;
				this.$refs.slide.initPage();
			},
			submitTask() {
				this.showPop = false;
				this.getAjax("api/en/iiwnzhuwpamb/xuwyzkawwn/subOrder", {}, "get").then(() => {
					this.showInfo("Submitted successfully");
					this.initPage();
				});
			},
			initPage() {
				//获取用户接单数量信息
				this.getAjax("api/en/iiwnzhuwpamb/xuwyzkawwn/userOrder/info", {}, "get").then((res) => {
					this.orderInfo = res.data;
				});
				//余额查询接口
				this.getAjax("api/en/iiwnzhuwpamb/xuwyzkawwn/getBalance", {}, "get").then((res2) => {
					this.userInfo = res2.data;
				});
			},
		},
		mounted() {
			this.getAjax("api/en/isnwhzuwp/nsjzuzaaim/page", {}, "get").then((res) => {
				this.defaultOption.limitMoveNum = res.data.list.length;
				this.listData = res.data.list;
				this.showLunbo = true;
			});
			this.initPage();
			//获取营业时间
			this.getAjax("api/en/isnwhzuwp/nsjzuzaaim/business", {}, "get").then((res) => {
				this.times = res.data;
			});
		},
	};
</script>

<style scoped lang="less">
	.conduc-gif-animation-wrapper {
		position: fixed;
		/* 使动画定位在屏幕的中央 */
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background-color: rgba(0, 0, 0, 0.5);
		/* 半透明背景 */
		display: flex;
		/* 使用flex布局居中内容 */
		align-items: center;
		justify-content: center;
	}
	
	.conduc-gif-animation-wrapper img {
		max-width: 200px;
		/* 限制GIF的大小 */
		max-height: 200px;
	}
	@keyframes conduc-scale {

		0%,
		100% {
			width: 65px;
			height: 65px;
		}

		20% {
			width: 60px;
			height: 45px;
		}

		30% {
			width: 55px;
			height: 40px;
		}

		40% {
			width: 70px;
			height: 70px;
		}

		50% {
			width: 60px;
			height: 60px;
		}

		60% {
			width: 65px;
			height: 65px;
		}
	}

	.conduc-seamless-warp {
		height: 280px;
	}

	.conduc-starting {
		height: 100%;
		overflow: hidden;
		overflow-y: auto;

		.conduc-head {
			width: 100%;
			height: 90px;
			background: url("@/assets/images/nav_bg.png") 0 -380px no-repeat;
			position: fixed;
			top: 0;
			left: 0;
			z-index: 10;

			.conduc-menu {
				width: 28px;
				position: absolute;
				top: 10px;
				left: 10px;
			}

			.conduc-title {
				color: #fff;
				font-size: 18px;
				font-weight: bold;
				padding-top: 13px;
				padding-left: 50px;
			}

			.conduc-ripe {
				width: 76px;
				margin: 10px auto;
				display: block;
			}
		}

		.conduc-content {
			padding: 90px 0 120px;

			h1 {
				color: #26a6c6;
				text-align: center;
				font-size: 20px;
				margin-bottom: 10px;
			}

			.conduc-lunboBox {
				width: 100%;
				height: 280px;
				overflow: hidden;

				.conduc-ul-scoll {
					width: 100%;
					height: 280px;
					white-space: nowrap;
					display: flex;

					li {
						width: 230px;
						height: 280px;

						img {
							height: 280px;
							width: 230px;
						}
					}
				}
			}

			.conduc-appBox {
				overflow: hidden;
				margin-top: 20px;

				.conduc-apps {
					width: 20%;
					float: left;
					height: 65px;
					display: flex;
					align-items: center;
					justify-content: center;

					.conduc-app {
						width: 65px;
						height: 65px;
						animation: conduc-scale 3s infinite;
					}

					&:nth-child(1) {
						.conduc-app {
							animation-delay: 0.2s;
						}
					}

					&:nth-child(2) {
						.conduc-app {
							animation-delay: 0.4s;
						}
					}

					&:nth-child(3) {
						.conduc-app {
							animation-delay: 0.6s;
						}
					}

					&:nth-child(4) {
						.conduc-app {
							animation-delay: 0.8s;
						}
					}

					&:nth-child(5) {
						.conduc-app {
							animation-delay: 1s;
						}
					}

					&:nth-child(6) {
						.conduc-app {
							animation-delay: 1.2s;
						}
					}

					&:nth-child(7) {
						.conduc-app {
							animation-delay: 1.4s;
						}
					}

					&:nth-child(8) {
						.conduc-app {
							animation-delay: 1.6s;
						}
					}

					&:nth-child(9) {
						.conduc-app {
							animation-delay: 1.8s;
						}
					}

					&:nth-child(10) {
						.conduc-app {
							animation-delay: 2s;
						}
					}

					&:nth-child(11) {
						.conduc-app {
							animation-delay: 2.2s;
						}
					}

					&:nth-child(12) {
						.conduc-app {
							animation-delay: 2.4s;
						}
					}

					&:nth-child(13) {
						.conduc-app {
							animation-delay: 2.6s;
						}
					}

					&:nth-child(14) {
						.conduc-app {
							animation-delay: 2.8s;
						}
					}

					&:nth-child(15) {
						.conduc-app {
							animation-delay: 3s;
						}
					}
				}
			}

			.conduc-num {
				text-align: center;
				margin-top: 10px;
				font-size: 15px;
			}

			.conduc-level {
				display: flex;
				align-items: center;
				justify-content: center;
				margin-top: 15px;

				img {
					width: 30px;
					margin-right: 5px;
				}

				span {
					color: #2ca9c8;
					font-size: 13px;
					font-weight: bold;
				}
			}

			.conduc-df_btn {
				margin: 15px;
			}

			.conduc-jieshaoBox {
				padding: 0 15px;
				display: flex;

				.conduc-item {
					flex: 1;
					flex-grow: 1;
					border: 1px solid #26a6c6;
					border-radius: 3px;
					padding: 0 15px;
					height: 220px;

					&:first-child {
						margin-right: 10px;
					}

					img {
						width: 70px;
						margin: 15px auto;
						display: block;
					}

					h3 {
						text-align: center;
						color: #2ca9c8;
						font-weight: normal;
					}

					h4 {
						text-align: center;
						color: #2ca9c8;
						margin-top: 15px;
					}

					p {
						font-size: 12px;
						transform: scale(0.9);
						width: 120%;
						margin-left: -10%;
						text-align: center;
						color: #777;
					}
				}
			}

			.conduc-tips {
				padding: 15px;

				.conduc-title {
					font-weight: bold;
				}

				p {
					font-size: 12px;
					color: #888;
					line-height: 1.8;

					&:before {
						content: "";
						width: 1px;
						height: 1px;
						display: inline-block;
						background: #888;
						border-radius: 100%;
						vertical-align: middle;
						margin-right: 5px;
					}
				}
			}
		}

		.conduc-task {
			padding: 15px;

			h2 {
				text-align: center;
				font-size: 15px;
				position: relative;
				color: #22b0c6;

				.conduc-iconClose {
					position: absolute;
					right: 0;
					top: 4px;
					font-size: 16px;
					font-weight: bold;
				}
			}

			img {
				display: block;
				margin: 20px auto 10px;
			}

			h3 {
				text-align: center;
				color: #777;
			}

			.conduc-money {
				display: flex;
				margin-top: 15px;
				border-bottom: 1px solid #22b0c6;
				padding-bottom: 20px;

				.conduc-item {
					flex: 1;
					flex-flow: 1;
					color: #22b0c6;

					.conduc-name {
						font-size: 12px;
						text-align: center;
					}

					.conduc-value {
						font-weight: bold;
						font-size: 16px;
						text-align: center;
						margin-top: 5px;
					}
				}
			}

			.conduc-line {
				height: 50px;
				display: flex;
				align-items: center;
				font-size: 13px;
				color: #777;
				border-bottom: 1px solid #22b0c6;

				span {
					flex: 1;
					font-size: 12px;
				}
			}

			.conduc-df_btn {
				height: 40px;
				line-height: 38px;
				margin: 20px auto 10px;
			}
		}
	}
</style>