<template>
  <div id="app">
    <div class="conduc-df_loading" v-if="isShowLoading">
      <van-loading size="60px" color="#fff"></van-loading>
    </div>
    <router-view />
  </div>
</template>
<script>
export default {
  data() {
    return {
      isShowLoading:false
    };
  },
  methods: {},
  mounted() {
    this.$EVENT.$on('loading',opt=>{
      this.isShowLoading = opt || false;
    })
  },
};
</script>
<style lang="less">
#app {
  width: 100%;
  height: 100%;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  .conduc-df_loading {
    width: 200px;
    height: 160px;
    background: rgba(0, 0, 0, 0.2);
    position: fixed;
    top: 50%;
    left: 50%;
    margin-left: -100px;
    margin-top: -80px;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.scroll {
  min-width: 1480px;
}
</style>
