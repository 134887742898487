<template>
  <div class="conduc-transaction">
    <Header title="Transaction" />
    <div class="conduc-content">
      <van-pull-refresh
        v-model="refreshing"
        @refresh="onRefresh"
        loading-text="Loading..."
        loosing-text=" "
        pulling-text=" "
      >
        <van-list
          v-model="loading"
          :finished="finished"
          @load="initPage"
          loading-text="Loading..."
          class="conduc-list"
        >
          <div class="conduc-box" v-for="item in dataList" :key="item.walletRecordId">
            <img
              src="@/assets/images/transaction.png"
              style="width: 50px"
              alt=""
            />
            <div class="conduc-timeBox">
              <h2>{{ item.title ? item.title : item.changeType }}</h2>
              <p>
                {{ item.changeTime | formatTime("YMDHMS") }}
                <span :class="item.isJian ? 'conduc-c_grey' : ''"
                  >{{ item.isJian ? "-" : "+"
                  }}{{
                    (parseFloat(item.changeBalance) / 100).toFixed(2)
                  }}</span
                >
              </p>
            </div>
          </div>
        </van-list>
      </van-pull-refresh>
    </div>
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
export default {
  components: { Header },
  data() {
    return {
      dataList: [],
      loading: false,
      finished: false,
      refreshing: false,
      params: {
        pageNum: 1,
        pageSize: 10,
        total: 100,
      },
    };
  },
  methods: {
    onRefresh() {
      this.params = {
        pageNum: 1,
        pageSize: 10,
        total: 100,
      };
      this.finished = false;
      this.dataList = [];
      this.initPage();
    },
    async initPage() {
      //获取充值记录
      if (this.dataList.length < this.params.total) {
        const res = await this.getAjax(
          "api/en/iiwnzhuwpamb/xuwyzkawwn/wallet",
          { ...this.params },
          "get"
        );
        this.loading = false;
        this.refreshing = false;
        this.params.total = res.data.total;
        res.data.list.forEach((e) => {
          if (
            e.changeType === "cash_out" ||
            e.changeType === "cash_out_success" ||
            e.changeType === "admin_sub" ||
            e.changeType === "task_sub"
          ) {
            this.$set(e, "isJian", true);
          } else {
            this.$set(e, "isJian", false);
          }
          this.dataList.push(e);
        });
        this.params.pageNum++;
      } else {
        this.finished = true;
      }
    },
  },
  mounted() {
    //获取充值记录
    this.dataList = [];
  },
};
</script>

<style lang="less" scoped>
.conduc-transaction {
  padding-top: 90px;
  .conduc-content {
    padding: 0 10px 10px;
    line-height: 1.6;
    .conduc-list {
      height: 100%;
      .conduc-box {
        padding: 5px 10px;
        display: flex;
        align-items: center;
        border-top: 1px solid #39c8c1;
        &:first-child {
          border-top: 0;
        }
        img {
          margin-right: 5px;
        }
        .conduc-timeBox {
          flex: 1;
          h2 {
            font-size: 15px;
            margin-top: 5px;
            color: #26abc3;
          }
          p {
            font-size: 12px;
            margin-top: 5px;
            color: #666;
            display: flex;
            span {
              flex: 1;
              text-align: right;
              color: #0fd161;
              font-weight: bold;
              font-size: 16px;
            }
            .conduc-c_grey {
              color: #929498;
            }
          }
        }
        .conduc-buttonBox {
          padding-top: 10px;
          .conduc-button {
            width: 75px;
            height: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #fff;
            font-size: 14px;
            background: #00cc66;
            border-radius: 3px;
          }
          .conduc-bg_red {
            background: #ff442b;
          }
          .conduc-bg_yellow {
            background: #facc01;
          }
          p {
            font-size: 16px;
            margin-top: 5px;
            color: #2faec6;
            font-weight: bold;
            text-align: right;
          }
        }
      }
    }
  }
}
</style>
